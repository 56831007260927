
  export default {
    name: 'SectionHomepageSearchButtons',
    components: {
      CarouselSearchButtons: () => import('@/components/CarouselSearchButtons'),
    },
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    serverCacheKey(props) {
      return 'section-homepage-search-buttons-' + props.section.id;
    },
    data() {
      return {
        emptyButtons: '',
      };
    },
  };
